import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
    this.should_save_card = null
    this.accept_cgu = null
    this.user_payment_method_id = null
    this.stripe = Stripe(this.data.get("stripe-public-key"));
    var elements = this.stripe.elements();
    var style = {
      base: {
        color: '#37474F',
        iconColor: '#37474F',
        fontFamily: 'Open Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        fontWeight: 600,
        '::placeholder': {
          color: '#a3b1c2'
        },
        ':-webkit-autofill': {
          color: '#37474F',
        },
      },
      invalid: {
        color: '#F44336',
        iconColor: '#F44336',
        ':-webkit-autofill': {
          color: '#F44336',
        },
      }
    };
    this.card = elements.create("card", { style: style });
    this.card.mount("#card-element");
    this.card.on("change", function (event_card) {
      document.querySelector("button").disabled = event_card.empty;
      document.querySelector("#card-errors").textContent = event_card.error ? event_card.error.message : "";
    });

    if (this.data.get('payment-method-present') == 'true'){
      $("#cb-0").prop("checked", true);
      this.user_payment_method_id = $('input[name=cb]:checked', '#payment-form').val()
      $('.js-new-cb-form').hide();
    }
    else{
      $("#cb-new").prop("checked", true);
      $('.js-new-cb-form').show();
    }
  }

  checkSavedCard(){
    var e = $(event.target)
    $(".js-toggle-checkbox").toggleClass('is-checked');
    this.should_save_card = e.prop("checked")? true : 'null'
  }

  checkAcceptCgu(){
    var e = $(event.target)
    this.accept_cgu = e.prop("checked")? true : 'null'
  }

  switchCard(){
    if ($('input[name=cb]:checked', '#payment-form').val() == 'new'){
      this.user_payment_method_id = null
      $('.js-new-cb-form').slideDown();
    }
    else{
      this.user_payment_method_id = $('input[name=cb]:checked', '#payment-form').val()
      $('.js-new-cb-form').slideUp();
    }
  }

  payShare(){
    event.preventDefault();
    var self = this;
    var share_path = this.data.get("share-path")
    var should_save_card = this.should_save_card
    var card = this.card
    var accept_cgu = this.accept_cgu
    var user_payment_method_id = this.user_payment_method_id
    var activity_date = this.data.get("activity-date")
    var activity_id = this.data.get("activity-id")
    var planning_id = this.data.get("planning-id")
    var processing = this.data.get("processing")
    var confirmation_path = this.data.get("confirmation-path")
    var button_text = this.data.get("button-text")
    var owner_empty = this.data.get("owner-empty")
    var stripe = this.stripe;
    var payment_method = stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        name: $("#name").val(),
      },
    })
    .then(function(result) {
      // Handle result.error or result.paymentMethod
      if ('error' in result && ($('input[name=cb]:checked', '#payment-form').val() == 'new')){
        var error_message = ''
        $("#card-errors").empty()
        if(result.error.code == "parameter_invalid_empty"){
          error_message = owner_empty
        }
        else{
          error_message = result.error.message
        }
        $("#card-errors").append(error_message)
        $("#card-element").toggleClass("StripeElement--complete StripeElement--invalid");
      }
      else{
        $.ajax({
          url: share_path,
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            payment_method_id: user_payment_method_id? user_payment_method_id : result.paymentMethod.id,
            start_date: activity_date,
            activity_id: activity_id,
            planning_id: planning_id,
            should_save_card: should_save_card,
            saved_card: user_payment_method_id,
            cgu_accepted: accept_cgu
          }),
          beforeSend: function() {
            $("#submit").attr("disabled", "disabled");
            $("#submit").html("<img class='mx-auto py-1' height='10' width='40' src='"+processing+"'/>");
          },
          success: function(data){
            if(data["error"] !== undefined){
              alert(data["error"])
              $("#submit").attr("disabled", false);
              $("#submit").html(button_text);
            }
            else{
              if(!data["requires_action"]){
                Turbolinks.visit(confirmation_path, { action: "replace" })
              }
            }
          },
          error: function (jqXHR, exception) {
            $("#submit").attr("disabled", false);
            $("#submit").html(button_text);
            if(typeof jqXHR.responseJSON !== 'undefined' && jqXHR.responseJSON.error == "cgu_not_accepted") {
              $(".cgu-checkbox").css("outline", "1px solid red")
              $(".cgu_error").toggleClass("hidden")
            }
            else {
              alert("Une erreur s'est produite")
            }
          }
        })
        .then(function(data) {
          if (data.requires_action) {
            self.handleServerResponse(data,self)
          }
        });
      }
    });
  }

  handleServerResponse(response,self) {
    var stripe = self.stripe;
    var confirmation_path = self.data.get("confirmation-path")
    if (response.error) {
      alert("error")
    } else if (response.requires_action) {
      // Use Stripe.js to handle required card action
      stripe.handleCardAction(
        response.payment_intent_client_secret
      ).then(function (result) {
        self.handleStripeJsResult(result,self)
       });
    } else {
      Turbolinks.visit(confirmation_path, { action: "replace" })
    }
  }

  handleStripeJsResult(result,self) {
    var share_path = self.data.get("share-path")
    var processing = self.data.get("processing")
    var activity_date = self.data.get("activity-date")
    var activity_id = self.data.get("activity-id")
    var planning_id = self.data.get("planning-id")
    var accept_cgu = self.accept_cgu
    var button_text = self.data.get("button-text")

    if (result.error) {
      $("#submit").attr("disabled", false);
      $("#submit").html(button_text);
      alert("Une erreur s'est produite avec votre banque")
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      $.ajax({
        url: share_path,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify({
          payment_intent_id: result.paymentIntent.id,
          start_date: activity_date,
          activity_id: activity_id,
          planning_id: planning_id,
          cgu_accepted: accept_cgu
        }),
        beforeSend: function () {
          $("#submit").attr("disabled", true);
          $("#submit").html("<img class='mx-auto py-1' height='10' width='40' src='"+processing+"'/>");
        }
      }).then(function (result) {
        self.handleServerResponse(result,self)
      });
    }
  }
}
