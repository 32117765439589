import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initGooglePlaces()
  }

  initGooglePlaces(){
    var autocomplete;

    if ( typeof(google)=='undefined' || typeof(google.maps)=='undefined' ){
      $.get({
          url: "https://maps.googleapis.com/maps/api/js?key="+ this.data.get("apiKey") +"&libraries=places",
          dataType: 'script',
          success: init,
        })
    }
    else{
      init()
    }

    function init() {
     var options = {
       types: ['geocode']
     };

     autocomplete = new google.maps.places.Autocomplete(document.getElementById('full_address'), options );

     autocomplete.setFields(['address_component', 'geometry']);

     autocomplete.addListener('place_changed', fillInAddress);

     google.maps.event.addDomListener(document.getElementById('full_address'), 'focus', e => e.target.setAttribute('autocomplete', 'new-password'));

    }

    function fillInAddress() {
      var place = autocomplete.getPlace();
      var area_1 = ""
      var area_2 = ""
      var area_3 = ""
      jQuery.each(place.address_components, function(i, val) {
        if(val.types[0] == "street_number"){
          $("#street").attr('value', val.long_name)
        }
        if(val.types[0] == "route"){
          var long_name = val.long_name
          $("#street").attr("value", function( i, val ) {
            return val + ' ' + long_name;
          });
        }
        else if(val.types[0] == "sublocality_level_1"){
          $("#street").attr('value', val.long_name)
        }
        else if(val.types[0] == "colloquial_area"){
          $("#street").attr('value', val.long_name)
        }
        else if(val.types[0] == "neighborhood"){
          $("#street").attr('value', val.long_name)
        }
        else if(val.types[0] == "postal_town"){
          $("#city").attr('value', val.long_name)
        }
        else if(val.types[0] == "locality"){
          $("#city").attr('value', val.long_name)
        }
        else if(val.types[0] == "administrative_area_level_1"){
          area_1 = val.long_name
          $("#state").attr('value', area_1)
        }
        else if(val.types[0] == "administrative_area_level_2"){
          area_2 = val.long_name
          $("#state").attr('value', area_2)
        }
        else if(val.types[0] == "administrative_area_level_3"){
          area_3 = val.long_name
          $("#state").attr('value', area_3)
        }
        else if(val.types[0] == "country"){
          var country = val.short_name
          if(country == "FR"){
            $("#state").attr('value', area_2)
          }
          $("#country").attr('value', val.short_name)
          $("#country option[value="+ val.short_name +"]").prop('selected', 'selected')
        }
        else if(val.types[0] == "postal_code"){
          $("#zip").attr('value', val.short_name)
        }
      });
    }
  }

}
