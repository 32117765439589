$(document).on('turbolinks:load', function() {
  var carousel_behaviour = function () {
    $('.js-mobile-carousel').each(function(){
      if( $(this).hasClass('single-carousel') ) {
        var $singleCarousel = $(this);
        if ($(window).width() > 768) {
          if ($singleCarousel.hasClass('slick-initialized')) {
            $singleCarousel.slick('unslick');
          }
        }
        else{
          if (!$singleCarousel.hasClass('slick-initialized')) {
            $singleCarousel.slick({
              arrows: false,
              dots: true,
              mobileFirst: true,
              infinite:false
            });
          }
        }
      } else if ( $(this).hasClass('center-carousel') ) {
        var $centerCarousel = $(this);
        if ($(window).width() > 768) {
          if ($centerCarousel.hasClass('slick-initialized')) {
            $centerCarousel.slick('unslick');
          }
        }
        else{
          if (!$centerCarousel.hasClass('slick-initialized')) {
            $centerCarousel.slick({
              arrows: false,
              dots: true,
              centerMode: false,
              slidesToShow: 1.3,
              slidesToScroll: 1,
              infinite: false,
              mobileFirst: true,
            });
          }
        }
      } else if ( $(this).hasClass('center-2-carousel') ) {
        var $center2Carousel = $(this);
        if ($(window).width() > 768) {
          if ($center2Carousel.hasClass('slick-initialized')) {
            $center2Carousel.slick('unslick');
          }
        }
        else{
          if (!$center2Carousel.hasClass('slick-initialized')) {
            $center2Carousel.slick({
              arrows: false,
              dots: true,
              centerMode: false,
              slidesToShow: 1.3,
              slidesToScroll: 1,
              infinite: false,
              mobileFirst: true,
              adaptiveHeight: false,
              responsive: [
                {
                  breakpoint: 330,
                  settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 2,
                  }
                },
              ]
            });
          }
        }
      } else if ( $(this).hasClass('center-2-carousel-no-bullet') ) {
        var $center2CarouselNoBullet = $(this);
        if ($(window).width() > 768) {
          if ($center2CarouselNoBullet.hasClass('slick-initialized')) {
            $center2CarouselNoBullet.slick('unslick');
          }
        }
        else{
          if (!$center2CarouselNoBullet.hasClass('slick-initialized')) {
            $center2CarouselNoBullet.slick({
              arrows: false,
              dots: false,
              centerMode: false,
              slidesToShow: 1.3,
              slidesToScroll: 1,
              infinite: false,
              mobileFirst: true,
              adaptiveHeight: false,
              responsive: [
                {
                  breakpoint: 330,
                  settings: {
                    slidesToShow: 2.3,
                    slidesToScroll: 2,
                  }
                },
              ]
            });
          }
        }
      }
    });
  }

  carousel_behaviour();

  $(window).on('resize orientationchange', function() {
    carousel_behaviour();
  });

  $('.js-carousel').each(function(){
    if( $(this).hasClass('single-carousel') ) {
      var $singleCarousel = $(this);
      $singleCarousel.slick({
        arrows: false,
        dots: true,
        mobileFirst: true,
        adaptiveHeight: false,
        infinite: false,
      });
    }
  });
});
