$(document).on('turbolinks:load', function() {

  $('input.js-form-where').on('click', function (e) {
    // make sure the input is visible even if mobile keyboard is in the way
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
    this.scrollIntoView()
  });

  var geolocationButton = function() {
    $('.js-form-where').on('click', function() {
      if( $(this).val() == "" && $(this).siblings('.js-geolocation').hasClass('max-h-0') ) {
        $(this).siblings('.js-geolocation').toggleClass('max-h-0 max-h-10');
        $(this).siblings('.js-geolocation').addClass('shadow border-b');
      }
    });
    $('.js-form-where').on('keyup', function() {
      if( $(this).siblings('.js-geolocation').hasClass('max-h-10') ) {
        $(this).siblings('.js-geolocation').toggleClass('max-h-10 max-h-0');
        $(this).siblings('.js-geolocation').removeClass('shadow border-b');
      }
      if( $(this).val() == "" && $(this).siblings('.js-geolocation').hasClass('max-h-0')) {
        $(this).siblings('.js-geolocation').toggleClass('max-h-10 max-h-0');
        $(this).siblings('.js-geolocation').addClass('shadow border-b');
      }
    });
    $(document).on('click', function(e) {
      var input = $('.js-form-where');
      if (!input.is(e.target) && input.has(e.target).length === 0) {
        if( $('.js-geolocation').hasClass('max-h-10') ) {
          $('.js-geolocation').toggleClass('max-h-10 max-h-0');
          $(this).siblings('.js-geolocation').removeClass('shadow border-b');
        }
      }
    });

    $('.js-geolocation').on('click', function() {
      $(this).siblings('input.js-form-where').val($(this).text());
      $(this).siblings('.js-geolocation-reset').removeClass('!hidden');
    })
  }

  if( $(window).width() < 768 ) {
    geolocationButton();
  }

  $(window).on('resize', function() {
    if( $(window).width() < 768 ) {
      geolocationButton();
    }
  })
});
