import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "sportsTrainerLabel", "sportsTrainerInput" ]

  connect(){
    if(this.sportsTrainerInputTarget.checked){
      this.sportsTrainerLabelTarget.classList.add('is-checked');
    }
    else{
      this.sportsTrainerLabelTarget.classList.remove('is-checked');
    }
  }

  checkSportsTrainer(){
    this.sportsTrainerLabelTarget.classList.toggle('is-checked');
  }
}
