import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  pointer(event){
    $('.js-favorites .icon-heart').css('cursor', 'pointer');
    $('.js-favorites .icon-heart-full').css('cursor', 'pointer');
  }

  follow(event){
    event.preventDefault();
    event.stopPropagation();
    var link = "create_" + $(event.target).data('activity-id')
    document.getElementsByClassName(link)[0].click()
  }

  unfollow(event){
    event.preventDefault();
    event.stopPropagation();
    var link = "delete_" + $(event.target).data('activity-id')
    document.getElementsByClassName(link)[0].click()
  }

  signIn(event){
    event.preventDefault();
    event.stopPropagation();
    window.location = $(".sign-in-link").attr("href");
  }


}
