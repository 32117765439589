$(document).on('turbolinks:load', function() {
  var change_area = $('.js-change-avatar'),
      change_link = $('.js-change-avatar-link'),
      change_form = $('.js-change-avatar-form'),
      change_close = $('.js-change-avatar-close');

  change_link.on( 'click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    change_form.slideToggle();
  });
  change_close.on( 'click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    change_form.slideUp();
  });

});