import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "handicappedLabel", "handicappedInput" ]

  connect(){
    if(this.handicappedInputTarget.checked){
      this.handicappedLabelTarget.classList.add('is-checked');
    }
    else{
      this.handicappedLabelTarget.classList.remove('is-checked');
    }
  }

  checkHandicapped(){
    this.handicappedLabelTarget.classList.toggle('is-checked');
  }
}
