import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  hideInput(){
    event.preventDefault();
    if($("#select-form").val() == 6 || $("#select-form").val() == 4){
      $("#sports-trainer-container").show()
    }
    else{
      $("#sports-trainer-container").hide()
    }
  }

}
