// Entry point for the build script in your package.json

import "./jquery"
import "./channels"
import './controllers'
import "./front"

import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
import Rails from "@rails/ujs"
import { App } from "./cable";

var Turbolinks = require("turbolinks")
Turbolinks.start()
Rails.start()
