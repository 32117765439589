import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
    this.stripe = Stripe(this.data.get("stripe-public-key"));
    var elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      },
    };
    var options = {
      style: style,
      supportedCountries: ['SEPA'],
      placeholderCountry: 'FR',
    };

    // Create an instance of the IBAN Element
    this.iban = elements.create('iban', options);

    // Add an instance of the IBAN Element into the `iban-element` <div>
    this.iban.mount('#iban-element');
    this.iban.on('change', function(event) {
      var displayError = document.getElementById('error-message');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  AddBankAccount(){
    event.preventDefault();
    var processing = this.data.get("processing")
    var stripe = this.stripe
    var iban = this.iban
    var form = document.getElementById("account_form");
    var bank_account_path = this.data.get("bank-account-path")
    var payment_methods_path = this.data.get("payment-methods-path")

    stripe.createToken(iban, {
      currency: 'eur',
      account_holder_name: $("#account_holder_name").val(),
    })
    .then(function(result) {
      // Handle result.error or result.source
      if ('error' in result){
        $("#error-message").append(result.error.message)
        $("#card-element").toggleClass("StripeElement--complete StripeElement--invalid");
      }
      else{
        if( result.error == null){
          $.ajax({
            url: bank_account_path,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify({
              token: result.token,
              account_name: $("#account_name").val(),
            }),
            beforeSend: function () {
              $("#submit").attr("disabled", "disabled");
              $("#submit").html("<img class='mx-auto py-1' height='10' width='40' src='"+processing+"'/>");
            },
            success: function(data){
              Turbolinks.visit(payment_methods_path, { action: "replace" })
            },
            error: function (jqXHR, exception) {
              $("#submit").attr("disabled", false);
              //$("#submit").html('');
              alert("Une erreur s'est produite")
            }
          })
        }
      }
    });
  }
}
