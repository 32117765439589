import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.closeAfterDelay()
  }
  
  closeAfterDelay() {
    setTimeout(() => {
      this.fadeOut()
    }, 5000)
  }

  fadeOut() {
    this.element.style.transition = "opacity 0.5s ease-out"
    this.element.style.opacity = 0
    setTimeout(() => {
      this.close()
    }, 500)
  }

  close(){
    $('.flash-messages-container').empty()
  }

  closeFlash(){
    $(event.target).remove()
  }

}
