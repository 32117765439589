$(document).on('turbolinks:load', function()  {
  $('[data-channel-subscribe="share"]').each(function(index, element) {
    var $element = $(element),
        share_id = $element.data('share-id')
        user_id = $element.data('user-id')
        receiver_id = $element.data('receiver-id')
        defaultTime = $('#timepicker').data('datachat-time-form-time')
        doneTypingInterval = 5000;
    var typingTimer;

    var flatpickr_share_config = {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultDate: defaultTime
    }
    if($(window).width() < 768 && $('[data-channel-subscribe="share"]').length ) {
      $("html, body").animate({ scrollTop: $('html, body').get(0).scrollHeight}, 1000);
    }
    $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)

    App["share_channel_" + share_id + "_" + user_id] = App.cable.subscriptions.create(
      {
        channel: "ShareChannel",
        share: share_id,
        user_id: user_id
      },
      {
        received: function(data) {
          $(".js-request-form").remove()
          $(".js-request").remove()
          if (data.typing == true) {
            if (!$( "#is_typing" ).length) {
              $element.append(data.message)
              if($(window).width() < 768 && $('[data-channel-subscribe="share"]').length ) {
                $("html, body").animate({ scrollTop: $('.chat').get(0).scrollHeight + 24}, 300);
              }
              $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)
            }
          }
          else if (data.typing == false){
            $("#is_typing").remove()
          }
          else{
            $("#is_typing").remove()
            $element.append(data.message)
            if($(window).width() < 768 && $('[data-channel-subscribe="share"]').length ) {
              $("html, body").animate({ scrollTop: $('.chat').get(0).scrollHeight + 24}, 300);
            }
            $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)
          }

          if (data.type === "share_paid") {
            $('.state-container').empty()
            $('.state-container').append(data.chat_open)
            $("[data-name='message-form']").html(data.message_form)
          }

          flatpickr("#timepicker", flatpickr_share_config)
        },

        is_typing: function(typing, share_id, receiver_id) {
          this.perform('is_typing', {"typing": typing, "share_id": share_id, "receiver_id": receiver_id})
        }

      }
    );

    $("#message").on("keyup", function(event) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(doneTyping, doneTypingInterval);
      App["share_channel_" + share_id + "_" + user_id].is_typing(true, share_id, receiver_id)
    });

    $("#message").on("keydown", function(event) {
      clearTimeout(typingTimer);
    });

    function doneTyping () {
      App["share_channel_" + share_id + "_" + user_id].is_typing(false, share_id, receiver_id)
    }

  });
});
