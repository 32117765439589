$(document).on('turbolinks:load', function() {
  $('.dropdown').on('click', function() {
    $(this).siblings('.dropdown-open').removeClass('dropdown-open');
    $(this).toggleClass('dropdown-open');
    $(this).find('.dropdown-button').attr('aria-expanded', function(index, attr){
      return attr == 'false' ? 'true' : 'false';
    });
  });
  $(document).mouseup(function (e){
    var container = $(".dropdown.dropdown-open");
    if (!container.is(e.target) && container.has(e.target).length === 0){
      container.removeClass('dropdown-open');
    }
  }); 
});